import React from 'react';
import { GlobalStyles as MUIGlobalStyles } from '@mui/material';


const GlobalStyles = () => {

  return (
    <MUIGlobalStyles
      styles={{
        html: {
          height: "100%"
        },
        body: {
          margin: 0,
          padding: 0,
          //backgroundColor: "rgb(15, 16, 22)",
          //overflow: "hidden",
          minHeight: "100%",
          height: "100%",
          fontFamily: "Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif",
        },
        "#root": {
          minHeight: "100%",
          height: "100%",
        },
        /*"*:not(.original-scrollbar)": {
          "&::-webkit-scrollbar-track": {
            borderRadius: "8px",
            backgroundColor: "#000",
          },
          "&::-webkit-scrollbar": {
            borderRadius: "21px",
            width: "8px",
            height: "8px",
            backgroundColor: "#000",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "21px",
            backgroundColor: "#fff",
          },
        },*/
      }}
    />
  );

}

export default GlobalStyles;
