import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) =>
	createStyles({
		backgroundImage: {
			backgroundSize: "cover",
		  backgroundPosition: "center",
			position: "fixed",
			height: "100%",
			width: "100%",
			zIndex: -1,
			"&::before": {
				content: "''",
				position: "absolute",
				top: 0,
				left: 0,
				height: "100%",
				width: "100%",
				backgroundColor: "rgba(0, 0, 0, 0.75)",
				backdropFilter: "blur(3px)",
			}
		},

	})
);
