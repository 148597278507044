import Axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from 'config';
import Uri from 'jsuri';


export const axios = Axios.create({
  baseURL: API_URL,
});

// axios.defaults.params = {
//   "access_token": "kemtai_guest"
// }

function authRequestInterceptor(config: AxiosRequestConfig) {
  const accessToken = window.localStorage.getItem("accessToken");

  if (accessToken) {
    config.url = String(new Uri(config.url).addQueryParam('access_token', accessToken));
  }

  return config;
}

axios.interceptors.request.use(authRequestInterceptor);
