
const mobilePattern = /iPhone|iPad|iPod|Android|Windows Phone|webOS/i
const androidPattern = /android/i
const tabletPattern = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i
const isNode = typeof(window) === "undefined"
const userAgent = !isNode ? navigator.userAgent : "";

export function isDesktop() : boolean {
    if (isNode) return true;

    if (mobilePattern.test(userAgent)) {
      return false;
    }
    if (tabletPattern.test(userAgent)) {
        return false;
    }
    return true;
}

export function isMobile() : boolean{
    return !isDesktop()
}

export function isTablet(): boolean {
    if (isNode) return false;
    const vw = Math.max(document.documentElement.clientWidth || 0,  window.innerWidth || 0 );
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    if (vw <= 1050 && vw > 500 && vh >= 800) {
      return true
    }
    return false;
}


export function isAndroid() {
    return androidPattern.test(userAgent);
}


export function deviceType() : string {
    if (tabletPattern.test(userAgent)) {
        return "Tablet" ;
    }
    if (mobilePattern.test(userAgent)) {
        return "Mobile";
    }
    return  "Computer";
}


export function supportsRecording() : boolean {
    if (isNode) return false;
    const mimeType =  'video/webm'
    if (!window.MediaRecorder)
        return false;
    if (!MediaRecorder.isTypeSupported)
        return false 
    return MediaRecorder.isTypeSupported(mimeType);
}

class Device {
    readonly isTablet = isTablet()
    readonly isMobile = isMobile()
    readonly isDesktop = isDesktop()
    readonly type = deviceType()
    readonly isAndroid = isAndroid()
}

export const device = new Device();
//export default device;
