import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React from 'react';

import GlobalStyles from './globalStyles';
import { light, dark } from './themes';

export const darkScrollbar = () => ({
	'&::-webkit-scrollbar-track': {
		borderRadius: '8px',
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
	},
	'&::-webkit-scrollbar': {
		width: '8px',
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
	},
	'&::-webkit-scrollbar-thumb': {
		borderRadius: '10px',
		backgroundColor: '#000',
	},
});


export const ThemeProvider: React.FC = ({ children }) => {

	const theme = createTheme(dark);

	return (
		<MuiThemeProvider theme={theme}>
			<CssBaseline />
			<GlobalStyles />

			{children}
		</MuiThemeProvider>
	);
};
