import TABS from './tabs';

class Constants {

  static instance: Constants|null = null;

  tabs = TABS;
  code = "Motion2022!";
  
  static getInstance() {
    if (!Constants.instance) {
      Constants.instance = new Constants();
    }

    return Constants.instance;
  }

};

const ConstantsInstance = Constants.getInstance();
export default ConstantsInstance;
