
import { GPU } from '@kemtai/utils';
import logger from '@kemtai/logger';

export function logVisit() {

    logger.event("demo-visit", {app:"demo",
        referrer:    document.referrer,
        url:         window.location.href,
        user_agent:  navigator.userAgent,
        gpu:         GPU(),
        screenSize : `${window.screen.height}x${window.screen.width}`
    })

}

export function logEmail(email:string, validPassword:boolean){
    console.log(email,validPassword)
    logger.event("demo-email", {email, validPassword})
}