import React from 'react';
import { Grid, CircularProgress } from '@mui/material';


const Loader = () => {

  return (
    <Grid container alignItems="center" justifyContent="center" sx={{ width: "100%", height: "100%" }}>
      <CircularProgress />
    </Grid>
  );

};

export default Loader;
