import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

export default makeStyles((theme: Theme) =>({
  dialog: {
    "&.MuiModal-root": {
      "& .MuiBackdrop-root": {
        backgroundColor: 'rgba(0,0,0,1)'
      },
      "& .MuiDialogTitle-root": {
        fontFamily: 'Gilroy-ExtraBold, Arial, Helvetica, sans-serif',
        fontSize: '1.1rem',
        letterSpacing: '.5px',
      },
      "& .MuiBox-root": {
        padding: '15px 40px 0',
        /*[theme.breakpoints.down("sm")]: {
          padding: '15px 0 0',
        },*/
      },
      "& .MuiDialogActions-root": {
        justifyContent: 'center',
        "& .MuiButton-root": {
          color: '#000000',
          minWidth: '150px',
          letterSpacing: '1px',
        }
      }
    }
  },
  textField: {
    "&.MuiFormControl-root": {
      "& .MuiInputBase-root": {
        "& .MuiInputBase-input.MuiOutlinedInput-input": {
          boxShadow: '0 0 0 100px #131319 inset !important',
          "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active": {
            boxShadow: '0 0 0 100px #131319 inset !important',
          }
        }
      }
    }
  }
}))