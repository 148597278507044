import KemtaiLogger from '@kemtai/logger';
import { axios } from 'libs/axios';
import { configure } from 'mobx';
import { logVisit } from 'libs/logging';
import { APP_VERSION } from 'config';


export const initApp = async () => {
  configure({
    enforceActions: "never"
  });

  KemtaiLogger.init({url:`https://v1.api.kemtai.com/log/`, app:"demo", version: APP_VERSION })
  logVisit() 
  //iframeWarmup("https://app.kemtai.com/");

  const response = await axios.post(`app/auth/guest/`, { uid: KemtaiLogger.userId });

  if (response.data.success) {
    window.localStorage.setItem("accessToken", response.data.tokens.access_token);
    window.localStorage.setItem("username", response.data.email);
  }
}
