import { AxiosError, AxiosResponse } from 'axios';
import { QueryClient, QueryFunctionContext, UseMutationOptions, DefaultOptions } from '@tanstack/react-query';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { PromiseValue } from 'type-fest';
import { axios } from './axios';


const queryConfig: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type ExtractFnReturnType<FnType extends (...args: any) => any> = PromiseValue<
  ReturnType<FnType>
>;

export type QueryConfig<QueryFnType extends (...args: any) => any> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
  'queryKey' | 'queryFn'
>;

export type MutationConfig<MutationFnType extends (...args: any) => any> = UseMutationOptions<
  ExtractFnReturnType<MutationFnType>,
  AxiosError,
  Parameters<MutationFnType>[0]
>;

type QueryFunctionOptionsT = {
  url: string,
  params?: object
};

export const fetcher = async <T>({
  url,
  params,
}: QueryFunctionOptionsT): Promise<T> => {
  const response = await axios.get<T>(url, { params });
  return response.data;
};

// export const useLoadMore = <T>(url: string | null, params?: object) => {
//   const context = useInfiniteQuery<
//     GetInfinitePagesInterface<T>,
//     Error,
//     GetInfinitePagesInterface<T>,
//     QueryKeyT
//   >(
//     [url!, params],
//     ({ queryKey, pageParam = 1 }) => fetcher({ queryKey, pageParam }),
//     {
//       getPreviousPageParam: (firstPage) => firstPage.previousId ?? false,
//       getNextPageParam: (lastPage) => {
//         return lastPage.nextId ?? false;
//       },
//     }
//   );
//
//   return context;
// };

// export const usePrefetch = <T>(url: string | null, params?: object) => {
//   const queryClient = useQueryClient();
//
//   return () => {
//     if (!url) {
//       return;
//     }
//
//     queryClient.prefetchQuery<T, Error, T, QueryKeyT>(
//       [url!, params],
//       () => fetcher({ url, params }),
//     );
//   };
// };

export const useFetch = <T>(options: UseQueryOptions<T, Error, T>) => {
  return useQuery(options);
};

const useGenericMutation = <T, S>(
  func: (data: T | S) => Promise<AxiosResponse<S>>,
  url: string,
  params?: object,
  updater?: ((oldData: T, newData: S) => T) | undefined
) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, T | S>(func, {
    onMutate: async (data) => {
      await queryClient.cancelQueries([url!, params]);

      const previousData = queryClient.getQueryData([url!, params]);

      queryClient.setQueryData<T>([url!, params], (oldData) => {
        return updater ? updater(oldData!, data as S) : (data as T);
      });

      return previousData;
    },
    onError: (err, _, context) => {
      queryClient.setQueryData([url!, params], context);
    },
    onSettled: () => {
      queryClient.invalidateQueries([url!, params]);
    },
  });
};

export const useDelete = <T>(
  url: string,
  params?: object,
  updater?: (oldData: T, id: string | number) => T
) => {
  return useGenericMutation<T, string | number>(
    (id) => axios.delete(`${url}/${id}`),
    url,
    params,
    updater
  );
};

export const usePost = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T
) => {
  return useGenericMutation<T, S>(
    (data) => axios.post<S>(url, data),
    url,
    params,
    updater
  );
};

export const useUpdate = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T
) => {
  return useGenericMutation<T, S>(
    (data) => axios.patch<S>(url, data),
    url,
    params,
    updater
  );
};
