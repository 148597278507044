


type CompressionFormat = "deflate"|"deflate-raw"|"gzip";

declare global {

    interface Window {
        CompressionStream: any;
    }

    class CompressionStream implements ReadableWritablePair<Uint8Array,Uint8Array> {
        constructor(type: CompressionFormat);
        readable: ReadableStream<Uint8Array>;
        writable: WritableStream<Uint8Array>;
    }

}



export async function gzip(data:string) : Promise<Uint8Array> {
    try {
        if (true || typeof(window.CompressionStream)=="undefined") {
            //console.log("no CompressionStream, using pako")
            const pako = await import("pako")
            const res = pako.gzip(data, {'level': 9 })
            //console.log("===> CompressionStream...",res)
            return res
        } else {
            //console.log("Using CompressionStream...")
            const blob = new Blob([data]);
            const x = blob.stream().pipeThrough(new CompressionStream("gzip"));
            const reader = x.getReader();
            let d : ReadableStreamReadResult<Uint8Array>;
            let res = new Uint8Array()
            do {
                 d = await reader.read();
                 if (d.value) {
                     let mergedArray = new Uint8Array(res.length + d.value!.length);
                     mergedArray.set(res);
                     mergedArray.set(d.value!, res.length);
                     res = mergedArray
                 }
            } while (!d.done)

            //const d : ReadableStreamReadResult<Uint8Array> = 
            //console.log("===> CompressionStream...",res)
            return res;
        }
    } catch {
        console.log("gzip catch...")
        return new Uint8Array()
        //return data
    }
}

export default gzip;
