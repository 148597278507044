import type { ITab } from 'types';
import img01 from "../assets/images/img01_1.png"
import img02 from "../assets/images/img02.png"
// import img03 from "../assets/images/img03.png"
import img04 from "../assets/images/img04.png"

const TABS: ITab[] = [
  {
    path: "/sample-workouts",
    title: "Sample Exercises",
    description: "Try a quick set of sample exercises to experience our motion tracking and user experience",
    image: img01,
  },
  {
    path: "/our-motion-tracking",
    title: "Computer Vision Simulator",
    description: "111 body data points, including the spine and joint angle analysis",
    image: img02,
  },
  // {
  //   path: "/our-exercises",
  //   title: "Extensive Exercise Library",
  //   description: "850+ different exercises and variations, including physiotherapy and fitness",
  //   image: img03,
  // },
  {
    path: "/range-of-motion",
    title: "Range of Motion Assessment",
    description: "The motion trainer supports various range of motion assessments",
    image: img04,
  },
];


export default TABS;
