import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Loader, Header } from 'components';
import { Box, useTheme } from '@mui/material';


const MainLayout = () => {

  const theme = useTheme();

  return (
    <>
      <Header />

      <Box
        sx={{
          [theme.breakpoints.down("md")]: {
            height: 'calc(100% - 42px)',
          },
          [theme.breakpoints.up("md")]: {
            height: 'calc(100% - 56px)',
          },
        }}
      >
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Box>
    </>
  );

}

export default MainLayout;
