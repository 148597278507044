import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Box,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField } from '@mui/material';
import DialogStyle from './style';
import Constants from 'Constants';
import { logEmail } from 'libs/logging';


export type LoginProps = {

};

const Login = (props: LoginProps) => {

  const navigate = useNavigate();
	const [error, setError] = React.useState('')
	const [coderror, setCoderror] = React.useState('')
	const [user, setUser] = React.useState({email:'', password:''})

	const storageFieldName = "kemtai";

	const clearErrors = ()=>{
		setError('')
		setCoderror('')
	}

	const keyPress = (e:any)=>{
		clearErrors()
		if(e.keyCode === 13){
			checkUser()
		}
	}

	const checkUser = ()=>{
		clearErrors()
		let valid = true
		// const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ // doesn't check ending and empty string
		const validRegex = /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/; // Used this https://www.w3resource.com/javascript/form/email-validation.php and added the '+' char.

		console.log(`CheckUser: ${user.email}`)
        logEmail(user.email, user.password==Constants.code)

		if(!user.email.length || !user.email.match(validRegex)){
			setError('Please enter valid email address')
			valid = false
		}
		if(!user.password.length || user.password!==Constants.code){
			setCoderror('Please enter valid Password')
			valid = false
		}
		if(valid){
			(window as any).sessionStorage[storageFieldName] = Constants.code;
			navigate("/");
		}
	}

	const classes = DialogStyle();

	return (
		<Dialog
			open={true}
			maxWidth={"xs"}
			className={classes.dialog}
			transitionDuration={{ enter: 0 }}
		>

			<DialogTitle sx={{px:3, pt:3, pb:2, m:0}}>Protected Area</DialogTitle>
			<DialogContent>
				<DialogContentText>To enter the page, please fill out the form below</DialogContentText>
				<Box sx={{pt: 2}}>
						<TextField
							margin="normal"
							id="useremail"
							label="Email Address"
							type="email"
							fullWidth
							variant="outlined"
							error={error.length>0}
							helperText={error}
							onKeyDown={keyPress}
							onChange={(event)=>{ setUser(prev=>({...prev, ...{email: event.target.value}})) }}
							className={ classes.textField }
						/>
						<TextField
							margin="normal"
							id="userpassword"
							label="Password"
							type="password"
							fullWidth
							variant="outlined"
							error={coderror.length>0}
							helperText={coderror}
							onKeyDown={keyPress}
							onChange={(event)=>{ setUser(prev=>({...prev, ...{password: event.target.value}})) }}
							className={ classes.textField }
						/>
				</Box>
			</DialogContent>
			<DialogActions sx={{px:3, pb:3}}>
				<Button variant="contained" size="large" disableElevation onClick={checkUser}>ENTER</Button>
			</DialogActions>
		</Dialog>
	);

};

export default Login;
