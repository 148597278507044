

function simpifyGPU(name:string)  {
    name = name.replace(/ ?\(TM\) ?/g, " ")
    name = name.replace(/ ?\(R\) ?/g, " ")
    return name
}


let $GPU:string|null|undefined = undefined


function getwebglCtx() {
    if (typeof OffscreenCanvas !== "undefined") {
        const canvas = new OffscreenCanvas(10,10)
        if (canvas) {
            const webglCtx = canvas.getContext("webgl") || canvas.getContext("webgl2")
            if (webglCtx) {
                return [canvas,webglCtx]
            } else {
                console.log("OffscreenCanvas without webgl: safari 16.4????")
            }
        }
    }

    const canvas = document.createElement('canvas');
    const webglCtx = canvas.getContext("webgl") || canvas.getContext("webgl2")
    return [canvas,webglCtx]
}

export function GPU() : string|null {
    //console.log("GPU 1")
    if ($GPU !== undefined) {
        return $GPU
    }
    //console.log("GPU 2")

    const [canvas,webglCtx] = getwebglCtx()

    try {

        if (webglCtx) {
            const debugInfo = (webglCtx as any).getExtension("WEBGL_debug_renderer_info")
            if  (debugInfo) {
                const gpu : string = (webglCtx as any).getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
                //console.log(">>>>>>>>>>>>>>>>GPU: ",gpu)
                const result = simpifyGPU(gpu);
                (webglCtx as any).getExtension('WEBGL_lose_context').loseContext();
                $GPU = result;
                return result;
            }
        }
    } catch (e) {
        
    } finally {
        if (canvas instanceof HTMLCanvasElement) {
            //console.log("canvas.remove")
            canvas.remove()
        }    
    }

    $GPU = null
    return null
}
