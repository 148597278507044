import React from 'react';
import { Grid, CircularProgress } from '@mui/material';
import BackgroundStyle from './Background.style';


type BackgroundProps = {
  src: string
};

const Background = (props: BackgroundProps) => {

  const classes = BackgroundStyle();

  return (
    <div className={classes.backgroundImage} style={{ backgroundImage: `url(${props.src})` }} />
  );

};

export default Background;
